import React, { useState, useEffect, useMemo } from 'react';
// import ProductDefaultImage from '../../../Assets/Products/productDefaultIMage.png';
import ProductDefaultImage from '../../../Assets/Products/productDefaultIMage.webp';
// import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
// import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import AddOutlinedIcon from '../../../Assets/Home/plusWhite.svg';
import RemoveOutlinedIcon from '../../../Assets/Home/minusWhite.svg';

import DeleteIcon from '../../../Assets/Store/delete.svg';
import {
  removeCartItem,
  updateCartItem,
} from '../../../redux/features/Cart/cartSlice';
import {
  GET_VARIANT_PRODUCT_QUANTITY,
  BASE_URL_SANDBOX,
  PRODUCT_IMAGES_S3_AMAZON_AWS,
} from '../../../Constants/Config';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Validation from '../../../Constants/Validation';

const SingleCartItem = ({ cartItem, singleStore, cartData, merchantId }) => {
  const dispatch = useDispatch();
  const VarientDataState = useSelector((state) => state.variantCart);
  const cartDataState = useSelector((state) => state.cart.cartData);
  const cartProductDetails = useSelector(
    (state) => state.cartProducts.cartProductDetails,
  );

  const isDisabled = useMemo(() => {
    return (
      (cartItem.product.quantity !== '0' &&
        cartItem.product.isstockcontinue === '0' &&
        cartItem.product.trackqnty === '1' &&
        cartItem.selectedQuantity >= +cartItem.product.quantity &&
        cartItem.product.isvarient !== '1') ??
      false
    );
  }, [cartItem]);

  const [disableQuantityUpdate, setdisableQuantityUpdate] =
    useState(isDisabled);

  const {
    removeProductFromCart,
    updateProductFromCart,
    handleImagesArray,
    filterCartData,
  } = Validation();

  // fetching cart products
  const getCartProducts = () => {
    if (
      cartDataState &&
      VarientDataState?.variantCartData &&
      cartProductDetails
    ) {
      const data = filterCartData(
        cartDataState,
        VarientDataState?.variantCartData,
        cartProductDetails,
      );

      return data ? data : [];
    }
  };

  // handling product qty in the cart drawer, checking if qty is available to add and its add button is disabled
  useEffect(() => {
    if (disableQuantityUpdate) {
      // getting cart products
      const cartProducts = getCartProducts();

      // storing all the products that are in cart, with complete info for each product
      const variantCartProductsInCart = [];
      VarientDataState.variantCartData?.forEach((prod) => {
        cartProducts.forEach((cartProduct) => {
          if (
            prod.id === cartProduct.variantId &&
            prod.product_id === cartProduct.productId
          ) {
            variantCartProductsInCart.push(prod);
          }
        });
      });

      // from the Cart products getting current product info using the cartItem prop.
      const currentProduct = variantCartProductsInCart.find(
        (prod) =>
          prod?.id === cartItem.variantId &&
          prod?.product_id === cartItem?.productId,
      );

      const productId = currentProduct?.product_id;
      const productPurchaseQTY = +currentProduct?.purchase_qty;

      // getting same PQ products that are in Cart with complete info for each product..
      const samePQProducts = variantCartProductsInCart.filter(
        (prod) =>
          prod?.product_id === productId &&
          +prod?.purchase_qty === productPurchaseQTY,
      );

      // for getting the selectedQuantity from the cart products
      const samePQProductsInCart = [];
      samePQProducts?.forEach((prod) => {
        cartProducts?.forEach((cartProduct) => {
          if (
            cartProduct?.productId === prod?.product_id &&
            cartProduct?.variantId === prod?.id
          ) {
            samePQProductsInCart.push(cartProduct);
          }
        });
      });

      const totalQty = samePQProductsInCart?.reduce(
        (acc, curr) => (acc += curr.selectedQuantity),
        0,
      );

      // console.log('totalQty: ', totalQty);
      // console.log('productPurchaseQTY: ', productPurchaseQTY);
      // console.log('disableQuantityUpdate: ', disableQuantityUpdate);
      // console.log('cartItem.selectedQuantity: ', cartItem.selectedQuantity);
      // console.log('currentProduct.quantity: ', currentProduct.quantity);

      // conditions for allowing to add more qty-
      // 1) all cart products qty (totalQty) should be less than PQ limit
      // 2) disableQuantityUpdate should be true
      // 3) current cart item quantity should be less than actual qty available (eg: a product has 5 qty and in cart its 2)
      if (
        totalQty < productPurchaseQTY &&
        disableQuantityUpdate &&
        cartItem.selectedQuantity < parseFloat(currentProduct.quantity)
      ) {
        setdisableQuantityUpdate(false);
      }
    }
  }, [
    cartItem,
    cartDataState,
    disableQuantityUpdate,
    VarientDataState?.variantCartData,
    cartProductDetails,
  ]);

  // disabling product if max qty reached in the cart drawer
  const allowUpdatingProduct = (variantId, productId, type, qty) => {
    // selected product... jisko update karna hai.. complete data hai isme
    const selectedProduct = VarientDataState?.variantCartData?.find(
      ({ id, product_id }) => variantId === id && productId === product_id,
    );
    // console.log('selectedProduct: ', selectedProduct);

    const cartProducts = getCartProducts();

    // console.log('cart products: ', cartProducts);
    const currentCartProduct = cartProducts.find(
      (prod) => prod.variantId === selectedProduct.id,
    );

    // productId & purchase qty match karke dusre products find kiye hai from cart & complete data hai isme each product ke
    const productsWithSamePQ = VarientDataState?.variantCartData?.filter(
      ({ product_id, purchase_qty }) =>
        product_id === selectedProduct.product_id &&
        selectedProduct.purchase_qty === purchase_qty,
    );
    // console.log('productsWithSamePQ: ', productsWithSamePQ);

    // just getting product info from cartData...
    let dataset = [];
    productsWithSamePQ?.forEach((prod) => {
      const temp = cartData.find(
        ({ productId, variantId }) =>
          prod.product_id === productId && prod.id === variantId,
      );

      dataset.push(temp);
    });

    // console.log('dataset: ', dataset);

    // only storing the products with same PQ but not selected product
    const otherProducts =
      dataset &&
      dataset.length > 0 &&
      dataset?.filter(
        (product) =>
          product?.productId === selectedProduct?.product_id &&
          product?.variantId !== selectedProduct?.id,
      );

    // total of each cart product qty
    const totalQty =
      otherProducts?.reduce((acc, curr) => {
        return (acc += parseFloat(curr.selectedQuantity));
      }, 0) + parseFloat(qty);

    // getting products max qty limit.
    const maxQty = (input) => (type === 'increment' ? input - 1 : input);

    const productPurchaseQTY = parseFloat(selectedProduct.purchase_qty);

    // for not allowing user to add 1 more qty of the product...
    if (maxQty(totalQty) === productPurchaseQTY) {
      setdisableQuantityUpdate(true);
      return false;
    }

    // for enabling button to add more qty of all the products that are in same PQ and have same product id.
    if (type === 'decrement') {
      productsWithSamePQ?.forEach((product) => {
        if (
          product.purchase_qty === productPurchaseQTY &&
          product.product_id === cartItem.product.id &&
          disableQuantityUpdate
        )
          setdisableQuantityUpdate(false);
      });
    }

    // if 1 more qty of the product is available for purchase then allowing...
    return true;
  };

  // on increasing or decreasing product qty inside the cart drawer
  const handleUpdateCartItem = async (product, qty, type) => {
    const { cartProductId, categoryId, productId, variantId } = product;

    // checking product qty.. if not available then disable the button
    if (product && product.varients && product.varients.length >= 1) {
      const bool = allowUpdatingProduct(variantId, productId, type, qty);
      if (!bool) return;

      const data = {
        merchant_id: product.product.merchant_id,
        product_id: productId,
        variant_id: variantId,
        quantity: qty,
      };
      try {
        const response = await axios.post(
          BASE_URL_SANDBOX + GET_VARIANT_PRODUCT_QUANTITY,
          data,
          { headers: { 'Content-Type': 'multipart/form-data' } },
        );

        if (
          response.data.status === 200 &&
          parseInt(response.data.result.quantity) >= qty
        ) {
          const cartItem = {
            cartProductId: cartProductId,
            categoryId: categoryId,
            productId: productId,
            quantity: qty,
          };

          const cartKey = `cart_${singleStore && singleStore.merchant_id}`;
          dispatch(updateCartItem(cartItem));
          updateProductFromCart(cartItem, cartKey, qty);
          setdisableQuantityUpdate(false);
        } else if (response.data.status === 400) {
          // console.log('out of stock from single cart item');
          setdisableQuantityUpdate(true);
        }
      } catch (error) {
        return new Error('Something went wrong');
      }
    } else {
      const data = {
        merchant_id: merchantId,
        product_id: productId,
        variant_id: '',
        quantity: qty,
      };

      try {
        const response = await axios.post(
          BASE_URL_SANDBOX + GET_VARIANT_PRODUCT_QUANTITY,
          data,
          { headers: { 'Content-Type': 'multipart/form-data' } },
        );

        if (
          response.data.status === 200 &&
          parseInt(response.data.result.quantity) >= qty
        ) {
          setdisableQuantityUpdate(false);

          const cartItemadd = {
            cartProductId: cartProductId,
            categoryId: categoryId,
            productId: productId,
            quantity: qty,
          };

          const cartKey = `cart_${singleStore && singleStore.merchant_id}`;
          dispatch(updateCartItem(cartItemadd));
          updateProductFromCart(cartItemadd, cartKey, qty);
        } else if (response.data.status === 400) {
          setdisableQuantityUpdate(true);
        }
      } catch (error) {
        return new Error('Something went wrong');
      }
    }
  };

  // on deleting a cart item inside the cart drawer
  const handleDeleteCartProduct = async (product) => {
    const { cartProductId, categoryId, productId } = product;
    const cartItemData = {
      cartProductId,
      categoryId,
      productId,
    };
    const cartKey = `cart_${singleStore && singleStore.merchant_id}`;

    if (product.variantId) {
      // current selected product with complete live data
      const selectedProduct = VarientDataState?.variantCartData?.find(
        (prod) =>
          product.variantId === prod?.id && productId === prod?.product_id,
      );

      // products inside cart with same product id and pq qty
      const productsWithSamePQ =
        VarientDataState?.variantCartData &&
        VarientDataState?.variantCartData.length > 0
          ? VarientDataState?.variantCartData?.filter(
              ({ product_id, purchase_qty }) =>
                product_id === selectedProduct.product_id &&
                selectedProduct.purchase_qty === purchase_qty,
            )
          : [];

      // products inside cart except the current product
      const otherProducts =
        productsWithSamePQ && productsWithSamePQ.length > 0
          ? productsWithSamePQ?.filter(
              ({ id, product_id }) =>
                selectedProduct.id !== id &&
                selectedProduct.product_id === product_id,
            )
          : [];

      if (otherProducts && otherProducts.length > 0) {
        otherProducts?.forEach(({ product_id }) => {
          const bool =
            product_id === cartItem?.product.id && disableQuantityUpdate;

          if (bool) {
            setdisableQuantityUpdate(false);
          }
        });
      }
    }

    dispatch(removeCartItem(cartItemData));
    removeProductFromCart(cartItemData, cartKey);
  };

  const imagesArray =
    cartItem &&
    cartItem.product &&
    cartItem.product.media &&
    handleImagesArray(cartItem.product.media);

  return (
    <>
      <div
        className={`quickvee-store-cart-single-item ${
          cartItem?.isOutOfStock ? 'error' : ''
        }`}
      >
        <div className="quickvee-scsi-image">
          <img
            onError={(e) => {
              e.target.src = ProductDefaultImage;
            }}
            // src={
            //   cartItem && cartItem.product && cartItem.product.media
            //     ? `${BASE_URL_SANDBOX}upload/products/${
            //         singleStore && singleStore.merchant_id
            //       }/${imagesArray[0]}`
            //     : ProductDefaultImage
            // }
            src={
              cartItem && cartItem.product && cartItem.product.media
                ? `${PRODUCT_IMAGES_S3_AMAZON_AWS}upload/products/${
                    singleStore && singleStore.merchant_id
                  }/${imagesArray[0]}`
                : ProductDefaultImage
            }
            width={55}
            height="auto"
            alt="product"
          />
        </div>
        <div className="quickvee-scsi-content">
          <span className="q-cart-product-name">
            {cartItem.product &&
            cartItem.product.title &&
            cartItem.product.title.length > 25
              ? cartItem.product.title.slice(0, 25) + `...`
              : cartItem.product.title}
          </span>
          {cartItem &&
            cartItem.varients &&
            cartItem.varients.map((varient, index) => (
              <p key={index}>
                {' '}
                {varient.varientName} :<span> {varient.varientVl} </span>{' '}
              </p>
            ))}
          <div className="q-cart-product-amount-section">
            <div className="q-cart-product-amount-update">
              <button
                disabled={cartItem.selectedQuantity <= 1}
                onClick={() =>
                  handleUpdateCartItem(
                    cartItem,
                    cartItem.selectedQuantity - 1,
                    'decrement',
                  )
                }
              >
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  // xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                >
                  <defs>
                    <clipPath id="clipPath">
                      <rect
                        id="Rectangle_3261"
                        data-name="Rectangle 3261"
                        width="16"
                        height="16"
                        transform="translate(-590 2518)"
                        fill="none"
                        stroke="#707070"
                        stroke-width="1"
                      />
                    </clipPath>
                  </defs>
                  <g
                    id="plus"
                    transform="translate(590 -2518)"
                    clipPath="url(#clipPath)"
                  >
                    <line
                      id="Line_687"
                      data-name="Line 687"
                      y2="11.25"
                      transform="translate(-576.375 2526) rotate(90)"
                      fill="none"
                      stroke="#fff"
                      stroke-width="2"
                    />
                  </g>
                </svg> */}
                <i className="fa-solid fa-minus fa-lg"></i>
              </button>
              <span> {cartItem && cartItem.selectedQuantity} </span>

              <button
                disabled={disableQuantityUpdate}
                onClick={() =>
                  handleUpdateCartItem(
                    cartItem,
                    cartItem.selectedQuantity + 1,
                    'increment',
                  )
                }
              >
                {' '}
                {/* <img src={AddOutlinedIcon} alt="add-icon" />{' '} */}
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  // xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  // style="filter: invert(0);"
                >
                  <defs>
                    <clipPath id="clipPath">
                      <rect
                        id="Rectangle_3261"
                        data-name="Rectangle 3261"
                        width="16"
                        height="16"
                        transform="translate(-590 2518)"
                        fill="none"
                        stroke="#707070"
                        stroke-width="1"
                      />
                    </clipPath>
                  </defs>
                  <g
                    id="plus"
                    transform="translate(590 -2518)"
                    clipPath="url(#clipPath)"
                    // style="&#10;"
                  >
                    <line
                      id="Line_686"
                      data-name="Line 686"
                      y2="11.25"
                      transform="translate(-582 2520.375)"
                      fill="none"
                      stroke="#fff"
                      stroke-width="2"
                    />
                    <line
                      id="Line_687"
                      data-name="Line 687"
                      y2="11.25"
                      transform="translate(-576.375 2526) rotate(90)"
                      fill="none"
                      stroke="#fff"
                      stroke-width="2"
                    />
                  </g>
                </svg> */}
                <i className="fa-solid fa-plus fa-lg"></i>
              </button>
            </div>
            <div className="q-cart-product-amount-calculation">
              <span>
                {' '}
                {cartItem && cartItem.price
                  ? `$ ${parseFloat(cartItem.price).toFixed(2)}`
                  : ''}{' '}
              </span>
              {/* <del> {cartItem && cartItem.comparePrice > 0 ? `$ ${cartItem.comparePrice}` : " "}  </del> */}
            </div>
          </div>
          <div className="q-cart-product-customize">
            <img
              src={DeleteIcon}
              width={32}
              alt="delete"
              onClick={() => handleDeleteCartProduct(cartItem)}
            />
          </div>
          {/* error will go here */}
          {cartItem?.pqLimitReached ? (
            <p className="no-stock-variant-product">
              Purchase Quantity Limit Reached!
            </p>
          ) : cartItem.isOutOfStock ? (
            <p className="no-stock-variant-product">Out of Stock!</p>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default SingleCartItem;
