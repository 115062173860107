import React, { useEffect, useState } from 'react';
import Zoom from 'react-img-zoom';
import {
  BASE_URL_SANDBOX,
  PRODUCT_IMAGES_S3_AMAZON_AWS,
} from '../../../Constants/Config';
// import ProductDefaultImage from '../../../Assets/Products/productDefaultIMage.png';
import ProductDefaultImage from '../../../Assets/Products/productDefaultIMage.webp';

const ImageZoom = ({ merchant_id, image }) => {
  // console.log(image)
  const [key, setKey] = useState(0);
  useEffect(() => {
    // console.log(image);
    setKey((prevKey) => prevKey + 1);
  }, [merchant_id, image]);

  const [imagedisplayCheck, setImageDisplay] = useState(false);
  function isBase64URL(str) {
    const regex = /^data:[\w\/\+]+;base64,([\w\/\+=]+)$/;
    return regex.test(str);
  }
  useEffect(() => {
    // console.log(image);
    let data = isBase64URL(image);
    setImageDisplay(data);
    setKey((prevKey) => prevKey + 1);
  }, [merchant_id, image]);

  return (
    <>
      <div className="single-product-zoom-div">
        <Zoom
          key={key}
          // img={
          //   image
          //     ? imagedisplayCheck == false
          //       ? `${BASE_URL_SANDBOX}upload/products/${merchant_id}/${image}`
          //       : ProductDefaultImage
          //     : ProductDefaultImage
          // }
          img={
            image
              ? imagedisplayCheck == false
                ? `${PRODUCT_IMAGES_S3_AMAZON_AWS}upload/products/${merchant_id}/${image}`
                : ProductDefaultImage
              : ProductDefaultImage
          }
          zoomScale={2}
          transitionTime={0.5}
          width={400}
          height={428}
        />
      </div>
    </>
  );
};

export default ImageZoom;
