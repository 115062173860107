import { useState } from 'react';
import { Grid } from '@mui/material';
import AlertModal from '../../ReusableComponent/AlertModal';
import { FormControl, MenuItem, Select, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PRODUCT_IMAGES_S3_AMAZON_AWS } from '../../../Constants/Config';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
const MobileViewPaymentWishList = ({
  ProductDefaultImage,
  cartItem,
  BASE_URL_SANDBOX,
  merchantId,
  imagesArray,
  handleDeleteCartProduct,
  handleUpdateCartItem,
  disableQuantityUpdate,
  getDefaultSelectedOption,
  handleVariant,
}) => {
  const [expanded, setExpanded] = useState(false);
  // console.log(!cartItem.allVarients);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Grid
        container
        className={`payment-page-wishlist-cart-items ${
          cartItem?.isOutOfStock ? 'error' : ''
        }`}
      >
        <Grid
          item
          xs={12}
          style={{ display: 'flex', flexDirection: 'column' }}
          className="payment-page-wishlist-item-content"
        >
          <Grid container>
            <Grid item xs={3}>
              <img
                onError={(e) => {
                  e.target.src = ProductDefaultImage;
                }}
                // src={
                //   cartItem && cartItem.product && cartItem.product.media
                //     ? `${BASE_URL_SANDBOX}upload/products/${merchantId}/${imagesArray[0]}`
                //     : ProductDefaultImage
                // }
                src={
                  cartItem && cartItem.product && cartItem.product.media
                    ? `${PRODUCT_IMAGES_S3_AMAZON_AWS}upload/products/${merchantId}/${imagesArray[0]}`
                    : ProductDefaultImage
                }
                width={60}
                height="auto"
                alt="product"
              />
            </Grid>
            <Grid item xs={8} className="top-content pe-4">
              <p className="">
                {cartItem &&
                  cartItem.product &&
                  cartItem.product.title &&
                  cartItem.product.title}
              </p>
              {/* <CloseOutlinedIcon  style={{cursor:"pointer"}} onClick={ handleDeleteCartProduct}  />  */}
            </Grid>
            <Grid item xs={1}>
              <AlertModal
                MoveWishlist={false}
                RemoveAllCart={false}
                removeSingleFromCart={true}
                handleDeleteCartProduct={() => {
                  handleDeleteCartProduct(cartItem);
                }}
              />
            </Grid>
          </Grid>

          <Grid container className="middle-content">
            <Grid item xs={6} className="increment-decremnt-button">
              <Grid container>
                <Grid
                  item
                  xs={10}
                  md={7}
                  lg={7}
                  xl={6}
                  xxl={5}
                  className="px-2"
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <button
                    disabled={cartItem.selectedQuantity <= 1}
                    onClick={() =>
                      handleUpdateCartItem(
                        cartItem,
                        cartItem.selectedQuantity - 1,
                        'decrement',
                      )
                    }
                  >
                    {' '}
                    {/* <img src={RemoveOutlinedIcon} alt="remove-icon" />{' '} */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      // xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <defs>
                        <clipPath id="clipPath">
                          <rect
                            id="Rectangle_3261"
                            data-name="Rectangle 3261"
                            width="16"
                            height="16"
                            transform="translate(-590 2518)"
                            fill="none"
                            stroke="#707070"
                            strokeWidth="1"
                          />
                        </clipPath>
                      </defs>
                      <g
                        id="plus"
                        transform="translate(590 -2518)"
                        clipPath="url(#clipPath)"
                      >
                        <line
                          id="Line_687"
                          data-name="Line 687"
                          y2="11.25"
                          transform="translate(-576.375 2526) rotate(90)"
                          fill="none"
                          stroke="#fff"
                          strokeWidth="2"
                        />
                      </g>
                    </svg>
                  </button>
                  <span>{cartItem && cartItem.selectedQuantity} </span>
                  <button
                    disabled={disableQuantityUpdate}
                    onClick={() =>
                      handleUpdateCartItem(
                        cartItem,
                        cartItem.selectedQuantity + 1,
                        'increment',
                      )
                    }
                  >
                    {' '}
                    {/* <img src={AddOutlinedIcon} alt="add-icon" />{' '} */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      // xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      // style="filter: invert(0);"
                    >
                      <defs>
                        <clipPath id="clipPath">
                          <rect
                            id="Rectangle_3261"
                            data-name="Rectangle 3261"
                            width="16"
                            height="16"
                            transform="translate(-590 2518)"
                            fill="none"
                            stroke="#707070"
                            strokeWidth="1"
                          />
                        </clipPath>
                      </defs>
                      <g
                        id="plus"
                        transform="translate(590 -2518)"
                        clipPath="url(#clipPath)"
                        // style="&#10;"
                      >
                        <line
                          id="Line_686"
                          data-name="Line 686"
                          y2="11.25"
                          transform="translate(-582 2520.375)"
                          fill="none"
                          stroke="#fff"
                          strokeWidth="2"
                        />
                        <line
                          id="Line_687"
                          data-name="Line 687"
                          y2="11.25"
                          transform="translate(-576.375 2526) rotate(90)"
                          fill="none"
                          stroke="#fff"
                          strokeWidth="2"
                        />
                      </g>
                    </svg>
                  </button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={5}
              style={{ marginRight: '' }}
              className="amount-calculation d-flex justify-content-end"
            >
              <span>
                {cartItem && cartItem.price
                  ? `$${cartItem?.price?.toFixed(2)}`
                  : ''}
              </span>
              {/* <del>$12.95</del> */}
            </Grid>
          </Grid>
          {cartItem && cartItem.allVarients && (
            <div
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
              className="w-100 px-2"
            >
              <div className="row">
                <div
                  className="col-12 d-flex align-items-center justify-content-between "
                  style={{ paddingRight: '0' }}
                >
                  <div>Edit Variant</div>
                  <div>
                    <ExpandMore
                      expand={expanded}
                      // onClick={handleExpandClick}
                      aria-expanded={expanded}
                      aria-label="show more"
                      className="w-100 px-3"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  </div>
                </div>
              </div>
            </div>
          )}

          <Collapse
            in={expanded}
            timeout="auto"
            unmountOnExit
            style={{ width: '100%' }}
          >
            <Grid container className="bottom-dropdowns-for-categories">
              {cartItem &&
                cartItem.allVarients &&
                cartItem.allVarients.map(
                  (varient, index) =>
                    varient[`optionsvl${index + 1}`].length >= 1 && (
                      <Grid
                        item
                        xs={12}
                        // style={
                        //   cartItem?.varients?.length >= 3
                        //     ? { width: '31%' }
                        //     : { width: '45%' }
                        // }
                        key={index}
                      >
                        <FormControl
                          fullWidth
                          style={{ padding: '0 2px' }}
                          // variant="filled"
                          className=" form-select-formcontrol my-2"
                        >
                          <InputLabel
                            id={`demo-simple-select-filled-label-${index}`}
                          >
                            <span
                              className=""
                              style={{ textTransform: 'capitalize' }}
                            >
                              {varient[`options${index + 1}`]}
                            </span>
                          </InputLabel>
                          <Select
                            labelId={`demo-simple-select-filled-label-${index}`}
                            className="input-field select-OnFocus"
                            id={`demo-simple-select-filled-${index}`}
                            name="varient"
                            variant="outlined"
                            label={varient[`options${index + 1}`]}
                            // inputProps={{ 'aria-label': 'outlined' }}
                            // defaultValue={getDefaultSelectedOption(
                            //   varient[`options${index + 1}`],
                            //   varient[`optionsvl${index + 1}`],
                            // )}
                            onChange={(e) =>
                              handleVariant(
                                e,
                                varient[`options${index + 1}`],
                                cartItem,
                              )
                            }
                            value={getDefaultSelectedOption(
                              varient[`options${index + 1}`],
                              varient[`optionsvl${index + 1}`],
                            )}
                          >
                            {varient[`optionsvl${index + 1}`].map(
                              (option, optionIndex) => (
                                <MenuItem key={optionIndex} value={option}>
                                  {option}
                                </MenuItem>
                              ),
                            )}
                          </Select>
                          {/* <span className="input-error">{values.errors.state}</span> */}
                        </FormControl>
                      </Grid>
                    ),
                  //   <p  > {varient.varientName} :<span> {varient.varientVl} </span> </p>
                )}
            </Grid>
          </Collapse>
          {Boolean(cartItem?.msg.trim()) ? (
            <p className="no-stock-variant-product">{cartItem?.msg}</p>
          ) : cartItem?.isOutOfStock && cartItem?.pqLimitReached ? (
            <p className="no-stock-variant-product">
              Purchase Quantity Limit Reached!
            </p>
          ) : cartItem?.isOutOfStock ? (
            <p className="no-stock-variant-product">Out of Stock!</p>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};

export default MobileViewPaymentWishList;
