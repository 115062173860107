export function formatNumber(input) {
  if (!input) return '0.00';

  // Parse input as a number
  const num = parseFloat(input);

  // Round to 3 decimal places and convert to string
  let rounded = num.toFixed(3);

  // Remove unnecessary trailing zeros
  rounded = rounded.replace(/(\.\d*[1-9])0+$|\.0*$/, '$1');

  return rounded;
}
